import { FC } from 'react';
import { Image } from 'antd';
import { toLower } from 'lodash';

type CountryImageType = {
  className?: string;
  position?: 'right' | 'left';
  src: string;
  text?: string;
  width?: number;
};

export const CountryImageText: FC<CountryImageType> = ({
  className,
  position = 'left',
  src,
  text,
  width = 13,
}) => {
  return (
    <div className={className}>
      {text && position == 'right' && <span className="mr-1.5">{text}</span>}
      <Image preview={false} src={`/images/countries/256x192/${toLower(src)}.png`} width={width} />
      {text && position == 'left' && <span className="ml-1.5">{text}</span>}
    </div>
  );
};
