import { FC, ReactNode } from 'react';

export const Description: FC<{ description: ReactNode; title: ReactNode; vertical?: boolean }> = ({
  description,
  title,
  vertical,
}) => {
  return (
    <div
      className={`grid xl:grid-cols-5 items-center py-3 xl:py-5 ${
        vertical ? 'text-center gap-2' : 'gap-5'
      }`}
    >
      <div className={`${vertical ? 'col-span-full' : 'xl:col-span-2'} text-slate-500`}>
        {title}
      </div>
      <div className={`${vertical ? 'col-span-full' : 'xl:col-span-2'} font-semibold`}>
        {description || '-'}
      </div>
    </div>
  );
};
