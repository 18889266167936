import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgExerciseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 384 384" width="24px" {...props}>
    <path
      d="M99.56,0c5.69,1.3,11.5,2.2,17.03,3.97,13.86,4.43,25.21,12.31,32.73,25.16,.69,1.17,2.42,2,3.84,2.44,16.88,5.19,30.33,14.82,39.42,30.16,2.49,4.21,2.17,8.53-.67,11.81-2.72,3.13-7.15,4.41-10.84,2.58-1.91-.94-3.82-2.54-4.92-4.34-7.33-11.94-17.94-19.25-31.45-22.24-5.6-1.24-9.03-4.01-11.61-9.25-5.74-11.69-16.4-16.74-28.99-19.64,0,7.39-.14,14.34,.05,21.28,.14,4.88-1.83,8.2-5.87,10.85-12.52,8.19-21.96,19.11-27.9,32.88-4.96,11.49-11.85,21.6-20.83,30.3-1.88,1.82-3.74,3.67-5.55,5.56-4.41,4.61-4.73,10.24-.85,14.35,3.91,4.14,9.65,4.27,14.35-.04,5.46-5.01,11.81-7.2,19.12-7.04,3.37,.08,6.74-.04,10.1,.02,12.85,.26,23.94-4.06,33.26-12.87,3.32-3.14,7.04-4.3,11.27-2.46,4.25,1.85,5.79,5.38,5.83,9.95,.23,28.14-8.96,52.73-27.28,74.06-6.09,7.09-11.94,14.41-17.52,21.9-11.92,16.01-17.01,34.38-18.1,54.55,1.51,0,2.84,0,4.17,0,24.2,0,48.4-.01,72.59,0,5.79,0,9.7,2.7,10.8,7.3,1.51,6.38-2.87,12-9.68,12.09-9.48,.12-18.96,.03-28.44,.03-19.21,0-38.42,0-57.63,.03-6.94,.01-9.66,2.81-9.7,9.82-.01,2.25-.04,4.49,0,6.74,.14,5.93,3.12,8.9,9.13,8.91,19.21,.04,38.42,.04,57.63,0,3.72,0,6.94,.92,9.18,4.02,2.23,3.08,2.55,6.49,.9,9.91-1.72,3.57-4.75,5.44-8.68,5.49-7.86,.1-15.72,.04-23.57,.04-11.85,0-23.7,.04-35.55,0-16.6-.06-28.27-11.62-28.44-28.19-.04-4.11-.11-8.26,.38-12.32,1.13-9.47,6.19-16.41,14.61-20.82,1.83-.96,2.8-1.88,2.89-4.15,1.14-29.13,11.67-54.5,30.67-76.52,5.53-6.41,10.79-13.09,15.89-19.86,7.23-9.59,12.03-20.33,14.31-32.16,.11-.58,.15-1.17,.29-2.29-12.51,6.26-25.55,6.42-38.7,6.36-2.47-.01-5.43,1.05-7.33,2.62-12.3,10.21-29.8,9.59-40.65-1.75-11.02-11.52-10.85-29.05,.39-40.86,4.3-4.51,9.04-8.69,12.77-13.63,3.8-5.02,7.17-10.54,9.73-16.29,6.85-15.41,16.97-28.06,30.19-38.41,1.21-.95,2.18-2.97,2.22-4.53,.22-7.73,.31-15.47,.04-23.19-.21-5.96,1.98-10.09,7.53-12.36h7.49Z"
      fill="currentColor"
    />
    <path
      d="M295.52,124.26c10.44-.59,19.03,2.25,25.7,9.73,4.75,5.32,7.25,11.66,7.27,18.76,.04,9.7-3.91,17.59-11.58,23.61-7.72,6.07-16.86,5.99-26.28,5.68,.32,11.32,1.7,22,4.52,32.49,3.32,12.33,9.38,23.3,16.91,33.5,12.02,16.27,20.51,34.13,24.35,54.06,.87,4.52,1.47,9.1,1.9,13.69,.2,2.18,.79,3.22,3.09,3.72,13.73,2.96,22.15,13.37,22.25,27.46,.03,4.48,.11,9.02-.54,13.42-1.93,13.07-12.91,22.77-26.15,22.8-53.89,.12-107.78,.13-161.66,0-14.33-.04-25.85-11.47-26.46-25.78-.22-5.22-.34-10.52,.28-15.69,1.26-10.6,10.12-19.76,20.7-22.09,1.2-.26,2.4-.53,3.75-.82,1.3-17.04,5.2-33.38,13.1-48.44,4.55-8.68,9.88-17.04,15.59-25.02,12.88-18,18.27-38.24,19.29-59.96,.04-.74,0-1.49-.04-2.24-.01-.22-.19-.43-.48-1.06-2.52,0-5.24,.03-7.95,0-16.68-.23-29.4-12.86-29.27-29.07,.13-16.09,12.77-28.53,29.24-28.77,.98-.01,1.96,0,3.64,0-10.02-14.8-12.75-30.31-6.88-46.84,3.8-10.7,10.73-19.05,20.38-25.05,19.38-12.04,44.42-9.15,60.63,6.83,15.44,15.22,21.01,41.64,4.71,65.09Zm-102.42,194.43h126.12c-.52-3.82-.91-7.38-1.51-10.91-3.22-18.99-11.77-35.54-23.13-50.88-13.08-17.66-20.09-37.68-22.26-59.47-.5-5.04-.95-10.08-1.42-15.13h-29.68c-.1,1.54-.18,2.88-.27,4.21-.9,14.11-3.04,27.97-7.73,41.38-4.76,13.59-12.83,25.25-20.96,36.93-11.21,16.09-17.42,34.03-19.16,53.86Zm63.27,19.65c-2.99,0-5.99,0-8.98,0-23.45,0-46.91-.02-70.36,.01-5.87,0-8.72,2.83-8.9,8.66-.07,2.12-.02,4.24-.02,6.36,.03,8.03,2.45,10.44,10.52,10.44,47.03,0,94.06,0,141.1,0,5.49,0,10.98,.08,16.47-.04,4.66-.1,7.7-3.07,8-7.7,.17-2.61,.06-5.24,.05-7.86-.03-7.16-2.77-9.87-10.03-9.87-25.95,0-51.9,0-77.85,0Zm29.63-243.97c-.02-16.64-13.28-29.95-29.89-29.99-16.47-.04-29.99,13.51-29.94,30,.05,16.47,13.61,29.87,30.16,29.81,16.37-.07,29.69-13.45,29.67-29.82Zm-29.59,49.3c-14.21,0-28.43-.02-42.64,.01-6.41,.01-10.72,3.84-10.78,9.42-.06,5.56,4.25,9.52,10.62,9.57,6.61,.05,13.21-.08,19.82-.07,21.94,.02,43.88,.13,65.82-.02,2.3-.02,4.9-1.08,6.8-2.43,3.09-2.2,3.92-6.59,2.61-10.16-1.44-3.94-4.69-6.27-9.23-6.28-14.34-.06-28.68-.02-43.02-.03Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgExerciseIcon);
export default Memo;
