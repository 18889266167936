import { Skeleton } from 'antd';

const BaseSkeleton = () => {
  return (
    <div className="bg-white p-10 md:p-15 mt-5 rounded-2xl">
      <Skeleton active round />
      <Skeleton active round />
      <Skeleton active round />
    </div>
  );
};

export default BaseSkeleton;
