import { FC, ReactNode } from 'react';
import { Button } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';

export const FileDownload: FC<{
  fileName: JSX.Element | JSX.Element[] | string | number | undefined;
  icon?: ReactNode;
  link?: string;
}> = ({ fileName, icon = <PaperClipOutlined />, link }) => {
  return (
    <Button
      className="flex items-center justify-center py-0 gap-2 px-4 md:px-8 "
      disabled={!link}
      href={link}
      icon={icon}
      rel="noreferrer"
      shape="round"
      target="_blank"
      type="default"
    >
      {fileName}
    </Button>
  );
};
