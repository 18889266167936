import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgMdVideoSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    height="24px"
    stroke="currentColor"
    strokeWidth={0}
    viewBox="0 0 24 24"
    width="24px"
    {...props}
  >
    <path d="M0 0h24v24H0z" fill="none" stroke="none" />
    <path
      d="M3 6h18v5h2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9v-2H3V6z"
      fill="currentColor"
      stroke="none"
    />
    <path
      d="M15 12L9 8v8zM22.71 18.43c.03-.29.04-.58.01-.86l1.07-.85c.1-.08.12-.21.06-.32l-1.03-1.79c-.06-.11-.19-.15-.31-.11l-1.28.5a3.44 3.44 0 00-.75-.42l-.2-1.36a.249.249 0 00-.25-.22h-2.07c-.12 0-.23.09-.25.21l-.2 1.36c-.26.11-.51.26-.74.42l-1.28-.5c-.12-.05-.25 0-.31.11l-1.03 1.79c-.06.11-.04.24.06.32l1.07.86c-.03.29-.04.58-.01.86l-1.07.85c-.1.08-.12.21-.06.32l1.03 1.79c.06.11.19.15.31.11l1.27-.5c.23.17.48.31.75.42l.2 1.36c.02.12.12.21.25.21h2.07c.12 0 .23-.09.25-.21l.2-1.36c.26-.11.51-.26.74-.42l1.28.5c.12.05.25 0 .31-.11l1.03-1.79c.06-.11.04-.24-.06-.32l-1.06-.85zM19 19.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
      fill="currentColor"
      stroke="none"
    />
  </svg>
);
const Memo = memo(SvgMdVideoSettingsIcon);
export default Memo;
