import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgLogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="currentColor" height="24px" viewBox="0 0 383.32 359.52" width="24px" {...props}>
    <path
      d="M0,64.42c1-4.22,1.75-8.49,3-12.65A71.62,71.62,0,0,1,71,.09C99-.08,126.9,0,154.84.06c7.37,0,12.72,5,12.82,11.78.1,7-5.3,12.14-12.82,12.15q-40.61,0-81.2,0C44.71,24,24,44.81,24,73.79q0,105.9,0,211.8c0,29,20.73,49.78,49.67,49.8q40.61,0,81.2,0c9.18,0,15.18,8.07,12,16.14-1.9,4.87-5.64,7.72-10.88,7.74-29.31.07-58.64.57-87.93-.17C34.2,358.26,5.08,331,.72,297.41c-.11-.85,0-1.77-.72-2.44Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M341.37,191.67H110.25A17.84,17.84,0,0,1,104,191a11.94,11.94,0,0,1,3-23.25c1.37-.1,2.75,0,4.12,0H341.5c-.26-1.65-1.5-2.36-2.38-3.24q-47.34-47.39-94.73-94.72C242,67.4,240,64.91,239.66,61.5a11.61,11.61,0,0,1,5.65-11.78,11.73,11.73,0,0,1,13.35.65,27.49,27.49,0,0,1,2.75,2.54q58.47,58.47,116.94,117c6.65,6.65,6.62,13.06-.09,19.77q-58.45,58.49-116.92,117c-3.82,3.83-8,5.92-13.43,4.22A11.89,11.89,0,0,1,242.18,292a38,38,0,0,1,2.85-3q47-47,94-93.92a30.21,30.21,0,0,1,2.92-2.34Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);
const Memo = memo(SvgLogoutIcon);
export default Memo;
