import { FC } from 'react';

export const TitleAndText: FC<{
  className?: string;
  horizontal?: boolean;
  light?: boolean;
  text: JSX.Element | JSX.Element[] | string | number | undefined;
  title: JSX.Element | string;
}> = ({ className = '', horizontal, light = false, text, title }) => {
  return light ? (
    <div className={`${horizontal ? '' : 'grid grid-cols-5'} gap-4 max-w-s min-w-xs ${className}`}>
      <div className="font-semibold col-span-2">{title}</div>
      <div className="col-span-3 text-gray-700 text-base space-y-1">{text || '-'}</div>
    </div>
  ) : (
    <div
      className={`md:grid ${
        horizontal ? '' : 'md:grid-cols-2'
      } hover:bg-ant-gray-2 md:space-y-0 space-y-1 px-4 py-3 ${className}`}
    >
      <div className="text-gray-600">{title}</div>
      <div>{text || '-'}</div>
    </div>
  );
};
