import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgGroupsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 384 384" width="24px" {...props}>
    <path
      d="M286.72,179.65c15.82,0,31.9-.17,47.98,.11,4.65,.08,9.51,.79,13.89,2.3,13.77,4.75,23.3,17.96,23.35,32.56,.16,40.54,.22,81.09-.01,121.64-.11,19.09-15.84,34.82-34.94,34.93-40.54,.23-81.09,.12-121.64,.03-8.68-.02-16.19-3.54-23.05-9.25-9.19,8.52-20.36,9.49-32.13,9.41-36.43-.25-72.86-.08-109.29-.09-22.96,0-38.16-15.24-38.16-38.25,0-38.42-.01-76.85,0-115.27,0-22.77,15.26-38.07,37.94-38.09,15.83-.01,31.67,0,47.69,0-3.29-13.97-1.6-27.93-1.72-41.8-.26-29.32-.09-58.63-.07-87.95,.01-22.53,15.3-37.91,37.74-37.92,38.67-.02,77.35-.02,116.02,0,22.52,0,37.84,15.3,37.85,37.8,.02,38.67,.05,77.35-.06,116.02-.01,4.54-.9,9.09-1.41,13.85Zm-82.37,23.95v4.29c0,41.77,0,83.54,0,125.31,0,9.62,4.54,14.11,14.25,14.11,38.4,0,76.81,0,115.21,0,9.73,0,14.25-4.47,14.26-14.1,0-38.4,0-76.81,0-115.21,0-9.99-4.39-14.4-14.33-14.4-41.65,0-83.29,0-124.94,0h-4.45ZM120.51,107.41c0,19.58-.01,39.17,0,58.75,0,8.64,4.8,13.48,13.38,13.48,38.92,.01,77.84,.01,116.75,0,8.89,0,13.57-4.72,13.57-13.67,.01-38.79,.01-77.59,0-116.38,0-8.96-4.68-13.66-13.57-13.66-38.92-.01-77.84-.01-116.75,0-8.58,0-13.38,4.83-13.38,13.47-.02,19.33,0,38.67,0,58Zm59.88,96.19h-3.85c-42.02,0-84.04,0-126.06,0-9.13,0-13.81,4.67-13.81,13.79,0,38.65,0,77.31,0,115.96,0,9.37,4.61,13.96,14.02,13.96,38.53,0,77.06,0,115.59,0,9.63,0,14.1-4.53,14.1-14.26,0-41.77,0-83.54,0-125.31,0-1.23,0-2.45,0-4.15Z"
      fill="currentColor"
    />
    <path
      d="M230.66,275.38c.65-20.45,12.8-39.1,31.83-46.62,19.9-7.87,38.14-4.41,54.12,9.92,4.79,4.3,5.81,9.77,3.23,14.86-2.46,4.86-7.63,7.55-13.15,6.12-2.51-.65-4.94-2.31-6.95-4.04-8.59-7.39-20.05-9.01-29.97-3.95-9.78,4.98-15.55,15.6-14.46,26.59,1.08,10.81,8.95,20.11,19.7,22.93,9.23,2.42,17.43,.28,24.73-5.9,2.03-1.72,4.44-3.39,6.95-4.03,5.54-1.42,10.69,1.26,13.15,6.12,2.51,4.97,1.44,10.32-2.95,14.62-26.51,26.03-72.14,14.41-83.24-21.3-1.54-4.94-2.03-10.2-3-15.31Z"
      fill="currentColor"
    />
    <path
      d="M192.38,57.61c4.62,.26,8.34,1.92,10.6,6.04,14.09,25.67,28.21,51.32,42.19,77.04,3.25,5.98,1.03,13.03-4.65,16.19-5.74,3.19-12.7,1.31-16.19-4.6-2.97-5.04-5.58-10.3-8.6-15.32-.66-1.09-2.39-2.17-3.64-2.18-13.22-.16-26.44-.15-39.67,0-1.15,.01-2.75,1.03-3.36,2.04-2.82,4.7-5.33,9.58-8.01,14.36-3.75,6.71-10.62,9.07-16.7,5.8-6.08-3.27-7.99-10.49-4.36-17.13,13.73-25.15,27.54-50.26,41.27-75.42,2.46-4.5,6.19-6.64,11.11-6.82Zm9.06,52.98c-3.15-5.71-5.97-10.82-9.1-16.49-3.21,5.93-5.99,11.06-8.93,16.49h18.03Z"
      fill="currentColor"
    />
    <path
      d="M134.27,267.73c7.91,7.09,12.2,15.66,11.93,26.17-.46,17.84-14.21,31.63-32.35,32.2-10.22,.32-20.45,.17-30.68,.04-7.33-.1-12.31-4.91-12.34-12.23-.12-25.69-.11-51.38,.01-77.07,.03-6.72,4.5-11.7,11-11.92,9.46-.33,19.01-.71,28.41,.19,20.27,1.95,31.89,21.48,24.72,40.58-.22,.58-.4,1.18-.7,2.05Zm-39.32,34.39c6.69,0,12.92,.25,19.12-.08,4.82-.26,8.06-4.04,8.19-8.64,.12-4.3-2.83-8.58-7-8.83-6.67-.4-13.38-.11-20.3-.11v17.66Zm0-41.98c4.62,0,9.08,.24,13.49-.09,2.9-.22,4.64-2.28,4.7-5.33,.07-3.2-1.62-5.47-4.68-5.74-4.4-.39-8.86-.1-13.51-.1v11.26Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgGroupsIcon);
export default Memo;
