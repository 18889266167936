import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgGuidelineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24px" viewBox="0 0 287.58 257.2" width="24px" {...props}>
    <path
      d="M287.54,114.07c0,25.33,.02,50.66,0,75.99-.02,21.36-13.1,36.08-34.47,37.57-18.08,1.26-36.02,2.97-53.49,8.02-12.26,3.54-24.07,8.21-34.9,15.01-14.02,8.8-27.71,8.66-41.81-.02-19.77-12.18-41.69-18.16-64.55-20.93-8.66-1.05-17.39-1.43-26.09-2.19C14.61,225.99,.22,211.09,.15,193.38c-.2-52.91-.21-105.81,0-158.72C.22,15.87,16.08-.3,34.92,0c35.95,.57,70.7,6.65,101.86,26.24,4.7,2.95,9.36,2.94,14.05-.03C175.23,10.79,202.32,3.87,230.69,1.27c8.78-.8,17.76-1.67,26.44-.69,17.44,1.97,30.25,17.15,30.36,34.87,.16,26.2,.04,52.41,.04,78.61h.01Zm-155.73,113.72v-4.22c0-50.77,0-101.54,0-152.31,0-6.11,.06-12.23-.06-18.34-.02-1.03-.5-2.55-1.27-3-5.37-3.14-10.78-6.23-16.33-9.03-24.01-12.11-49.91-15.89-76.36-17.06-8.71-.38-13.78,4.67-13.78,13.43-.01,51.02,0,102.04,.01,153.06,0,1.48,.07,3.01,.44,4.43,1.58,6.04,5.95,9.04,13.67,9.15,25.2,.37,49.49,5.31,72.87,14.61,6.9,2.75,13.6,6.03,20.82,9.27Zm23.96,.15c1.06-.44,1.63-.63,2.16-.91,29.09-14.96,59.97-22.67,92.68-23.2,8.5-.14,12.96-5.02,12.96-13.55,.01-27.44,0-54.89,0-82.33,0-23.7,.02-47.4-.01-71.11-.01-7.96-5.04-13.3-12.68-13.03-21.39,.75-42.6,3.12-62.69,10.89-9.93,3.84-19.14,9.5-28.83,13.99-2.8,1.3-3.68,2.73-3.67,5.75,.09,56.39,.07,112.77,.07,169.16v4.33Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgGuidelineIcon);
export default Memo;
